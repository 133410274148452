import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  Progress,
  Select,
  useToast,
  Icon,
  Container,
  Heading,
} from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import { transcriptionApi } from '../services/api';

interface TranscriptionResponse {
  id: number;
  status: 'pending' | 'processing' | 'completed' | 'failed';
  text?: string;
  error?: string;
}

const TranscriptionUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [language, setLanguage] = useState('en');
  const [model, setModel] = useState('base');
  const toast = useToast();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.size > 100000000) { // 100MB
        toast({
          title: 'File too large',
          description: 'Maximum file size is 100MB',
          status: 'error',
          duration: 3000,
        });
        return;
      }
      setSelectedFile(file);
      setTranscriptionText('');
      setUploadProgress(0);
    }
  };

  const onUploadProgress = useCallback((progress: number) => {
    setUploadProgress(progress);
  }, []);

  const checkTranscriptionStatus = useCallback(async (transcriptionId: number) => {
    try {
      const response = await transcriptionApi.getStatus(transcriptionId);
      const data: TranscriptionResponse = response.data;
      
      if (data.status === 'completed') {
        setTranscriptionText(data.text || '');
        setIsProcessing(false);
        setUploadProgress(0);
        toast({
          title: 'Transcription completed',
          status: 'success',
          duration: 3000,
        });
      } else if (data.status === 'failed') {
        setIsProcessing(false);
        setUploadProgress(0);
        toast({
          title: 'Transcription failed',
          description: data.error,
          status: 'error',
          duration: 3000,
        });
      } else {
        setTimeout(() => checkTranscriptionStatus(transcriptionId), 2000);
      }
    } catch (error) {
      console.error('Error checking status:', error);
      setIsProcessing(false);
      setUploadProgress(0);
      toast({
        title: 'Error checking status',
        status: 'error',
        duration: 3000,
      });
    }
  }, [toast]);

  const handleUpload = async () => {
    if (!selectedFile) return;

    setIsProcessing(true);
    setUploadProgress(0);

    try {
      const response = await transcriptionApi.upload(
        selectedFile, 
        language, 
        model,
        onUploadProgress
      );
      
      const data: TranscriptionResponse = response.data;

      toast({
        title: 'File uploaded successfully',
        description: 'Starting transcription...',
        status: 'success',
        duration: 3000,
      });

      checkTranscriptionStatus(data.id);

    } catch (error: any) {
      setIsProcessing(false);
      setUploadProgress(0);
      toast({
        title: 'Upload failed',
        description: error.response?.data?.detail || 'An error occurred',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const getProgressText = () => {
    if (uploadProgress === 100 && isProcessing) {
      return 'Transcribing...';
    }
    if (uploadProgress > 0) {
      return `Uploading: ${uploadProgress}%`;
    }
    return 'Starting upload...';
  };

  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={8} align="stretch">
        <Heading textAlign="center" size="lg">
          Video to Text Transcription
        </Heading>

        <Box
          borderWidth={2}
          borderRadius="lg"
          p={10}
          borderStyle="dashed"
          borderColor="gray.300"
          bg="gray.50"
          _hover={{ bg: 'gray.100' }}
          cursor="pointer"
          onClick={() => document.getElementById('file-upload')?.click()}
        >
          <input
            id="file-upload"
            type="file"
            accept="video/*,audio/*"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          <VStack spacing={4}>
            <Icon as={FiUploadCloud} w={12} h={12} color="gray.400" />
            <Text fontSize="lg" color="gray.500">
              Click or drag video file to upload
            </Text>
            {selectedFile && (
              <Text color="blue.500" fontWeight="bold">
                Selected: {selectedFile.name}
              </Text>
            )}
          </VStack>
        </Box>

        <Select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          isDisabled={isProcessing}
        >
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="fr">French</option>
          <option value="de">German</option>
          <option value="hi">Hindi</option>
          <option value="ja">Japanese</option>
          <option value="zh">Chinese</option>
        </Select>

        <Select
          value={model}
          onChange={(e) => setModel(e.target.value)}
          isDisabled={isProcessing}
        >
          <option value="tiny">Tiny (Fastest)</option>
          <option value="base">Base</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large (Most Accurate)</option>
        </Select>

        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleUpload}
          isLoading={isProcessing}
          loadingText="Processing..."
          isDisabled={!selectedFile || isProcessing}
        >
          Start Transcription
        </Button>

        {(uploadProgress > 0 || isProcessing) && !transcriptionText && (
          <Box>
            <Progress
              value={uploadProgress}
              size="md"
              colorScheme="blue"
              hasStripe
              isAnimated
            />
            <Text mt={2} textAlign="center">
              {getProgressText()}
            </Text>
          </Box>
        )}

        {transcriptionText && (
          <Box
            p={6}
            borderWidth={1}
            borderRadius="lg"
            bg="white"
            shadow="sm"
          >
            <Text fontWeight="bold" mb={4}>
              Transcription Result:
            </Text>
            <Text whiteSpace="pre-wrap">{transcriptionText}</Text>
          </Box>
        )}
      </VStack>
    </Container>
  );
};

export default TranscriptionUpload;