import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true
});

export interface UploadProgressCallback {
    (progress: number): void;
}

export const transcriptionApi = {
    upload: async (file: File, language: string, model: string, onProgress: UploadProgressCallback) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('language', language);
        formData.append('model_size', model);
        
        return api.post('/transcription/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
            timeout: 300000, // 5 minutes
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                }
            }
        });
    },

    getStatus: async (id: number) => {
        return api.get(`/transcription/${id}`, {
            withCredentials: true
        });
    },
};

export default api;